import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getToken, getUser } from '~/helpers/utility';
import { clearLocalStorage } from "~/store/auth/saga";
import {
  AuthStateType, LoginActionType,
  LoginSuccessActionType,
  UIRefreshActionType,
} from './types';

export const authInitialState: AuthStateType = {
  user: getUser(),
  token: getToken(),
  loading: false,
  isAuth: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: authInitialState,
  reducers: {
    login: (_state: AuthStateType, _action: PayloadAction<LoginActionType>) => {},
    loginError: (_state: AuthStateType, _action: PayloadAction<void>) => {},
    loginSuccess: (
      state: AuthStateType,
      { payload }: PayloadAction<LoginSuccessActionType>
    ) => {
      state.user = payload.branch;
      state.token = payload.token;
    },
    uiRefresh: (
      state: AuthStateType,
      { payload }: PayloadAction<UIRefreshActionType>
    ) => {
      state.loading = payload.loading;
    },
    setIsAuth:  (
      state: AuthStateType,
      { payload }: PayloadAction<boolean>
    ) => {
      state.isAuth = payload;
    },
    logout: (
      state: AuthStateType,
      _action: PayloadAction<void>
    ) => {
      state.token = null;
      state.user = null;
      clearLocalStorage();
    },
  },
});

export const { login, loginError, loginSuccess, setIsAuth, uiRefresh, logout } = authSlice.actions;
export default authSlice.reducer;
