import { IOrder, IOrders } from "~/models/IOrder";
import { ProductDataStatus } from '~/shared/const/productStatus';

export const ADD_ORDER = 'ADD_ORDER';
export const ORDER_STATUS_UPDATE = 'ORDER_STATUS_UPDATE';
export const PRODUCT_STATUS_UPDATE = 'PRODUCT_STATUS_UPDATE';

export type OrderStateType = {
  orders: IOrder[];
  loading: boolean;
  btnLoading: boolean;
  filterValue: ProductDataStatus;
  completedOrderId: number | null;
  lastOrderUpdatingDate: Date | null;
};

export interface ListRefreshActionType {
  data: IOrders;
}

export interface AddOrderActionType {
  type: typeof ADD_ORDER;
  data: IOrder;
}

export interface OrderStatusCompletedActionType {
  type: typeof ORDER_STATUS_UPDATE;
  data: {
    orderID: number;
    completed: string;
  };
}

export interface ProductStatusCompletedActionType {
  type: typeof PRODUCT_STATUS_UPDATE;
  data: {
    orderID: number;
    productID: number;
    status: ProductDataStatus;
  };
}

export interface OrderStatusCompletedSuccessActionType {
  orderID: number;
  completed: string;
}

export interface ProductStatusUpdateActionType {
  data: {
    orderID: number;
    productID: number;
    status: ProductDataStatus;
  };
}

export interface ProductStatusUpdateSuccessActionType {
  data: {
    orderID: number;
    productID: number;
    status: ProductDataStatus;
  };
}

export interface UIRefreshActionType {
  loading: boolean;
}

export interface StatusUIRefreshActionType {
  loading: boolean;
}
