import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ListRefreshActionType, PartnerStateType } from './types';

export const partnerInitialState: PartnerStateType = {
  partners: [],
  loading: false,
};

const partnerSlice = createSlice({
  name: 'partner',
  initialState: partnerInitialState,
  reducers: {
    listReload: (_state: PartnerStateType, _action: PayloadAction) => {},
    listRefresh: (
      state: PartnerStateType,
      { payload }: PayloadAction<ListRefreshActionType>
    ) => {
      state.partners = payload.data;
    },
    uiRefresh: (
      state: PartnerStateType,
      { payload }: PayloadAction<boolean>
    ) => {
      state.loading = payload;
    },
  },
});
export const { listReload, listRefresh, uiRefresh } = partnerSlice.actions;
export default partnerSlice.reducer;
