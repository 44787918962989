import { RouteNames } from "~/app/providers/router/config/routeConfig";
import { getAudioNotification } from "~/helpers/utility";

function playNotification(audioUrl: string | undefined): void {
  try {
    new Audio(audioUrl).play();
  } catch (e) {
    console.log("The file does not play");
  }
}

export function playOrderUpdateNotification(allowedPaths: RouteNames): void {
  if (allowedPaths.includes(window.location.pathname)) {
    const audioUrl = getAudioNotification();

    if (audioUrl.endsWith(".mp3")) {
      playNotification(audioUrl);
    } else {
      console.log("The audio file is missing or the line does not end with '.mp3'");
    }
  }
}
