import { all, call, fork, takeEvery } from 'redux-saga/effects';
import { watchMessages } from './watcher';
import orderSaga from './order/externalSaga';
import socketSaga from './socket/externalSaga';
import settingsSocketSaga from "~/sockets/settings/externalSaga";
import authSocketSaga from "~/sockets/auth/externalSaga";

export default function* externalSagas() {
  const channel = yield call(watchMessages);

  yield takeEvery(channel, function* (action) {
    yield all([fork(authSocketSaga, action), fork(socketSaga, action), fork(orderSaga, action), fork(settingsSocketSaga, action)]);
  });
}
