import { all, fork, put } from 'redux-saga/effects';
import { setConnectionStatus as reduxSocketActions }  from '~/store/socket/slice';
import actions from './actions';

function* updateConnectionStatus(action) {
  switch (action.type) {
    case actions.SOCKET_CONNECTION_STATUS_SET: {
      yield put(reduxSocketActions(action.payload));
      break;
    }

    default:
      break;
  }
}

export default function* socketSaga(action) {
  yield all([fork(updateConnectionStatus, action)]);
}
