import { all, call, fork, put } from "redux-saga/effects";
import { logout } from "~/store/auth/slice";
import { LOGOUT } from "~/store/auth/types";

function* authSaga(action) {
  switch (action.type) {
    case LOGOUT: {
      yield call(logoutSaga);
      break;
    }

    default:
      break;
  }
}

function* logoutSaga() {
  yield put(logout());
}

export default function* authSocketSaga(action) {
  yield all([fork(authSaga, action)]);
}
