import { IUserSettings } from "~/models/IUserSettings";

export const LOGOUT = 'LOGOUT';

export type AuthStateType = {
  user: IUserSettings | null;
  token: string | null;
  loading: boolean;
  isAuth: boolean;
};

export type LoginCredentials = {
  partner: string;
  login: string;
  password: string;
};

export interface LoginActionType {
  data: LoginCredentials;
}

export interface LoginSuccessActionType {
  token: string;
  branch: IUserSettings;
}

export interface UIRefreshActionType {
  loading: boolean;
}

export interface LogoutActionType {
  type: typeof LOGOUT;
}
