import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IOrder } from '~/models/IOrder';
import { OrderStatus } from '~/shared/const/orderStatus';
import { ProductDataStatus } from '~/shared/const/productStatus';
import {
  ListRefreshActionType,
  OrderStateType,
  OrderStatusCompletedSuccessActionType,
  ProductStatusUpdateActionType,
  ProductStatusUpdateSuccessActionType,
  StatusUIRefreshActionType,
  UIRefreshActionType,
} from './types';

export const orderInitialState: OrderStateType = {
  orders: [],
  loading: false,
  btnLoading: false,
  filterValue: ProductDataStatus.OVERDUE_AND_PREPARE,
  completedOrderId: null,
  lastOrderUpdatingDate: null,
};

const orderSlice = createSlice({
  name: 'order',
  initialState: orderInitialState,
  reducers: {
    listReload: (_state: OrderStateType, _action: PayloadAction) => {},
    listRefresh: (
      state: OrderStateType,
      { payload }: PayloadAction<ListRefreshActionType>
    ) => {
      state.lastOrderUpdatingDate = new Date();
      state.orders = payload.data.orders.map(order => {
        if (order.ready_status === OrderStatus.NEW) {
          order.ready_status = OrderStatus.PROCESSING;
        }

        const updatedProducts = order.products.map(product => {
          if (
            !product.ready_status ||
            product.ready_status === ProductDataStatus.NEW
          ) {
            return { ...product, ready_status: ProductDataStatus.PROCESSING };
          }

          return product;
        });

        return { ...order, products: updatedProducts };
      });
    },
    addOrder: (state: OrderStateType, { payload }: PayloadAction<IOrder>) => {
      const orderExists = state.orders.some(order => order.order_name === payload.order_name);

      if (orderExists) return;

      const updatedProducts = payload.products.map(product => ({
        ...product,
        ready_status: ProductDataStatus.PROCESSING,
      }));

      const updatedData = {
        ...payload,
        ready_status: OrderStatus.PROCESSING,
        products: updatedProducts,
      };

      state.lastOrderUpdatingDate = new Date();
      state.orders.push(updatedData);
    },
    removeOrder: (state: OrderStateType, { payload }: PayloadAction<{ orderID: number }>) => {
      state.orders = state.orders.filter(order => order.id !== payload.orderID);
    },
    setCompletedOrderId: (
      state: OrderStateType,
      { payload }: PayloadAction<OrderStatusCompletedSuccessActionType>
    ) => {
      state.completedOrderId = payload.orderID;
    },
    orderCompleted: (
      state: OrderStateType,
      { payload }: PayloadAction<OrderStatusCompletedSuccessActionType>
    ) => {
      const { orderID, completed } = payload;

      state.lastOrderUpdatingDate = new Date();
      state.orders = state.orders.map(order => {
        if (order.id === orderID) {
          const updatedProducts = order.products.map(product => {
              return { ...product, ready_status: ProductDataStatus.COMPLETED };
          });

          return {
            ...order,
            completed,
            ready_status: OrderStatus.COMPLETED,
            products: updatedProducts
          };
        }

        return order;
      });
    },
    productStatusUpdate: (
      _state: OrderStateType,
      _action: PayloadAction<ProductStatusUpdateActionType>
      ) => {},
    productStatusUpdateSuccess: (
      state: OrderStateType,
      { payload: { data } }: PayloadAction<ProductStatusUpdateSuccessActionType>
    ) => {
      const { orderID, productID, status } = data;

      state.lastOrderUpdatingDate = new Date();
      state.orders = state.orders.map(order => {
        if (order.id === orderID) {
          const updatedProducts = order.products.map(product => {
            if (product.id === productID) {
              return { ...product, ready_status: status };
            }

            return product;
          });

          return { ...order, products: updatedProducts };
        }

        return order;
      });
    },
    uiRefresh: (
      state: OrderStateType,
      { payload }: PayloadAction<UIRefreshActionType>
    ) => {
      state.loading = payload.loading;
    },
    statusUiRefresh: (
      state: OrderStateType,
      { payload }: PayloadAction<StatusUIRefreshActionType>
    ) => {
      state.btnLoading = payload.loading;
    },
    setStatusFilterValue: (
      state: OrderStateType,
      { payload }: PayloadAction<ProductDataStatus>
    ) => {
      state.filterValue = payload;
    },
  },
});

export const {
  listReload,
  listRefresh,
  addOrder,
  removeOrder,
  setCompletedOrderId,
  orderCompleted,
  productStatusUpdate,
  productStatusUpdateSuccess,
  uiRefresh,
  statusUiRefresh,
  setStatusFilterValue,
} = orderSlice.actions;
export default orderSlice.reducer;
