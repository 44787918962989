import { all, call, fork, put } from 'redux-saga/effects';
import { RouteNames } from '~/app/providers/router/config/routeConfig';
import { delay } from "~/helpers/utils";
import { messages } from '~/store/orders/saga';
import { addOrder, orderCompleted, productStatusUpdateSuccess, setCompletedOrderId } from "~/store/orders/slice";
import { ADD_ORDER, ORDER_STATUS_UPDATE, PRODUCT_STATUS_UPDATE } from "~/store/orders/types";
import { showNotificationByPage } from "~/utils/local_notification";
import { playOrderUpdateNotification } from "~/utils/notification_sound";

function* updateOrder(action) {
  switch (action.type) {
    case ADD_ORDER: {
      yield call(addNewOrder, action);
      break;
    }

    case ORDER_STATUS_UPDATE: {
      yield call(orderStatusCompleted, action);
      break;
    }

    case PRODUCT_STATUS_UPDATE: {
      yield call(productStatusUpdated, action);
      break;
    }

    default:
      break;
  }
}

function* addNewOrder({ data }) {
  yield put(addOrder(data));

  showNotificationByPage(RouteNames.KITCHEN_LINK, 'success',`${messages.newOrderAdded} N:${data.order_name}`);
  playOrderUpdateNotification([RouteNames.KITCHEN_LINK, RouteNames.USERS_LINK])
}

function* orderStatusCompleted({ data }) {
  yield put(setCompletedOrderId(data));
  yield delay(3000);
  yield put(orderCompleted(data));

  showNotificationByPage(RouteNames.KITCHEN_LINK,'success', messages.successOrderCompleted);
  playOrderUpdateNotification([RouteNames.USERS_LINK])
}

function* productStatusUpdated({ data }) {
  showNotificationByPage(RouteNames.KITCHEN_LINK, 'success', messages.successStatusUpdate);
  yield put(productStatusUpdateSuccess({ data }));
}

export default function* orderSocketSaga(action) {
  yield all([fork(updateOrder, action)]);
}
