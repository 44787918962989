import { eventChannel } from 'redux-saga';
import subscribe from './subscribes';
import getSocket from './../helpers/sockets/socket';

export function watchMessages() {
  const socket = getSocket();

  return eventChannel((emit) => {
    subscribe(socket, emit);

    // unsubscribe
    return () => {
      console.log('Socket off');
    };
  });
}
