import { IBranch } from "~/models/IBranch";
import { IUserSettings } from "~/models/IUserSettings";

export const SETTINGS_UPDATE = 'SETTINGS_UPDATE';

export type SettingStateType = {
  settings: IUserSettings;
  settingsUpdated: boolean;
  loading: boolean;
};

export interface SettingsRefreshActionType {
  type: typeof SETTINGS_UPDATE;
  data: IBranch;
}
export interface SettingsRefreshSuccessActionType {
  data: IUserSettings;
}

export interface UIRefreshActionType {
  loading: boolean;
}
