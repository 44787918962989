import { IOrder, IOrderStatusChange } from "~/models/IOrder";
import {
  ADD_ORDER,
  AddOrderActionType,
  ORDER_STATUS_UPDATE,
  OrderStatusCompletedActionType,
  PRODUCT_STATUS_UPDATE,
  ProductStatusCompletedActionType
} from "~/store/orders/types";

export function addOrder(data: IOrder): AddOrderActionType {
  return {
    type: ADD_ORDER,
    data,
  };
}

export function orderStatusUpdate(
  data: IOrder
): OrderStatusCompletedActionType {
  const { id, completed } = data;

  return {
    type: ORDER_STATUS_UPDATE,
    data: {
      completed,
      orderID: id,
    },
  };
}

export function productStatusUpdate(
  data: IOrderStatusChange
): ProductStatusCompletedActionType {
  const { order_id, product_id, ready_status} = data;

  return {
    type: PRODUCT_STATUS_UPDATE,
    data: {
      orderID: +order_id,
      productID: +product_id,
      status: ready_status,
    },
  };
}
