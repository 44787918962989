import React, { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes } from 'react-router-dom';
import { RouteNames, routeConfig } from '~/app/providers/router/config/routeConfig';
import { ProtectedRoutes } from '~/app/providers/router/ui/ProtectedRoutes';
import { getRawToken } from '~/helpers/utility';

import { HotFoundPage } from '~/pages/HotFoundPage';
import { LoginPage } from '~/pages/LoginPage';
import { useAppDispatch } from '~/store';
import { loginSelector } from '~/store/auth/auth.selector';
import { setIsAuth } from '~/store/auth/slice';
import { settingsReload } from "~/store/setting/slice";

const AppRouter = () => {
  const dispatch = useAppDispatch();
  const isAuth = useSelector(loginSelector.isAuth);

  useEffect(() => {
  	if (getRawToken()) {
  		dispatch(setIsAuth(true));
      dispatch(settingsReload());
  	}
  }, [dispatch]);

  return (
    <Routes>
      <Route
        path={RouteNames.LOGIN_LINK}
        element={isAuth ? <Navigate to={RouteNames.HOME_LINK} /> : <LoginPage />}
      />
      <Route element={<ProtectedRoutes />}>
        {Object.values(routeConfig).map(({ element, path }) => (
          <Route
            key={path}
            path={path}
            element={
              <Suspense>{element}</Suspense>
            }
          />
        ))}
      </Route>
      <Route path="*" element={<HotFoundPage />} />
    </Routes>
  );
};

export default AppRouter;
