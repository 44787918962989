import React from "react";
import { Button } from "antd";
import './ErrorPage.scss';

interface ErrorPageProps {
    className?: string;
}

export const ErrorPage = ({ className }: ErrorPageProps) => {
    const reloadPage = (): void => {
        location.reload();
    };

    return (
        <div className='errorPage'>
            <h2>An unexpected error has occurred</h2>
            <Button onClick={reloadPage}>Reload page</Button>
        </div>
    );
};
