import { all } from 'redux-saga/effects';
import authSaga from '~/store/auth/saga';
import partnerSaga from '~/store/partner/saga';
import ordersSaga from '~/store/orders/saga';
import socketSaga from '~/store/socket/saga';
import settingsSaga from '~/store/setting/saga';
import externalSocketSagas from '../sockets/sagas';

export default function* rootSaga(): Generator {
  yield all([
    partnerSaga(),
    authSaga(),
    settingsSaga(),
    socketSaga(),
    ordersSaga(),
    externalSocketSagas(),
  ]);
}
