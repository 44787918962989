import { AnyAction, combineReducers } from "redux";
import partnerReducer, { partnerInitialState } from "~/store/partner/slice";
import authReducer, { authInitialState } from "~/store/auth/slice";
import socketReducer, { socketInitialState } from "~/store/socket/slice";
import settingsReducer, { settingsInitialState } from "~/store/setting/slice";
import orderReducer, { orderInitialState } from "~/store/orders/slice";

const combinedReducer = combineReducers({
  partner: partnerReducer,
  auth: authReducer,
  socket: socketReducer,
  setting: settingsReducer,
  order: orderReducer,
});

export type RootState = ReturnType<typeof combinedReducer>;

const rootReducer: any = (state: RootState, action: AnyAction) => {
  if (action.type === "auth/logout") {
    const initialRootState = {
      partner: partnerInitialState,
      auth: authInitialState,
      socket: socketInitialState,
      setting: settingsInitialState,
      order: orderInitialState,
    }

    return combinedReducer(initialRootState, action); // Reset the state to initial state
  }
  return combinedReducer(state, action);
};

export default rootReducer;
