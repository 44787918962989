import {
  all,
  call,
  fork,
  put,
  takeLatest,
} from 'redux-saga/effects';
import { RouteNames } from "~/app/providers/router/config/routeConfig";
import { ordersAPI } from '~/helpers/api/orders';
import { showNotificationByPage } from "~/utils/local_notification";
import {
  listRefresh,
  listReload,
  productStatusUpdate,
  productStatusUpdateSuccess,
  statusUiRefresh,
  uiRefresh,
} from './slice';

export const messages = {
  errorListLoad: 'Loading order list failed',
  newOrderAdded: 'New order added',
  errorStatusUpdate: 'Updating order status failed',
  successStatusUpdate: 'Order status has been updated',
  successOrderCompleted: 'Order completed',
};

function* listReloadSaga() {
  yield takeLatest(listReload.type, function* () {
    yield put(uiRefresh({ loading: true }));

    try {
      const response = yield call(ordersAPI.orderList);

      if (response && response.status === 200) {
        yield put(listRefresh(response));
      }
    } catch (error) {
      showNotificationByPage(RouteNames.KITCHEN_LINK, 'error', messages.errorListLoad, error.message);
    }

    yield put(uiRefresh({ loading: false }));
  });
}

function* productStatusUpdateSaga() {
  yield takeLatest(productStatusUpdate.type, function* ({ payload }) {
    yield put(statusUiRefresh({ loading: true }));

    const { orderID, productID, status } = payload.data;

    const data = {
      ready_status: status,
    };

    try {
      const response = yield call(
        ordersAPI.orderProductStatusUpdate,
        orderID,
        productID,
        data
      );

      if (response && response.status === 200) {
        yield put(
          productStatusUpdateSuccess({
            data: { orderID, productID, status: data.ready_status },
          })
        );
      }
    } catch (error) {
      showNotificationByPage(RouteNames.KITCHEN_LINK, 'error', messages.errorStatusUpdate, error.message);
    }

    yield put(statusUiRefresh({ loading: false }));
  });
}

export default function* orderListSaga() {
  yield all([fork(listReloadSaga), fork(productStatusUpdateSaga)]);
}
