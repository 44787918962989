import React, { FC, ReactNode } from 'react';
import './Layout.scss';
import { siteConfig } from '~/shared/config/config';

interface LayoutProps {
	children: ReactNode;
}

const Layout: FC<LayoutProps>  = ({ children }) => {
	return (
		<>
			<main>{children}</main>
			{siteConfig.footerText && <footer>{siteConfig.footerText}(c)</footer>}
		</>
	);
}

export default Layout;
