import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { getUser } from "~/helpers/utility";
import { SettingStateType, SettingsRefreshSuccessActionType, UIRefreshActionType } from "./types";

export const settingsInitialState: SettingStateType = {
  settings: getUser(),
  settingsUpdated: false,
  loading: false,
};

const settingsSlice = createSlice({
  name: 'setting',
  initialState: settingsInitialState,
  reducers: {
    settingsReload: (_state: SettingStateType, _action: PayloadAction<void>) => {},
    settingsRefresh: (
      state: SettingStateType,
      { payload }: PayloadAction<SettingsRefreshSuccessActionType>
    ) => {
      state.settings = payload.data;
    },
    setSettingsUpdatedValue: (
      state: SettingStateType,
      { payload }: PayloadAction<boolean>
    ) => {
      state.settingsUpdated = payload;
    },
    uiRefresh: (
      state: SettingStateType,
      { payload }: PayloadAction<UIRefreshActionType>
    ) => {
      state.loading = payload.loading;
    },
  },
});
export const {
  settingsReload,
  settingsRefresh,
  setSettingsUpdatedValue,
  uiRefresh
} = settingsSlice.actions;
export default settingsSlice.reducer;
