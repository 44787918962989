import { ProductDataStatus } from "~/shared/const/productStatus";
import { apiRequest } from './index';
/* ------------------------------------------------------------------------------------------------
    Main
------------------------------------------------------------------------------------------------ */

// get list of orders
function orderList(params = {}) {
  const req = {
    method: 'GET',
    url: '/receipts?page=1&per_page=100',
    params,
  };

  return apiRequest(req);
}

// update order
function orderProductStatusUpdate(orderID: string, productID: string, data: { ready_status: ProductDataStatus }, additionalParams = {}) {
  const req = {
    method: 'PATCH',
    url: `/receipt/${orderID}/${productID}`,
    params: { ...additionalParams },
    data,
  };

  return apiRequest(req);
}

export const ordersAPI = {
  // Main
  orderList,
  orderProductStatusUpdate,
};
