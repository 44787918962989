import { LoginCredentials } from '~/store/auth/types';
import { apiRequest } from './index';

export function apiLogin(authData: LoginCredentials) {
  const req = {
    method: 'POST',
    url: '/branch/login',
    data: authData,
  };

  return apiRequest(req);
}

export const authApi = {
  apiLogin,
};
