import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { RouteNames } from "~/app/providers/router/config/routeConfig";
import { settingsAPI } from "~/helpers/api/settings";
import { setUser } from "~/helpers/utility";
import { uiRefresh } from "~/store/orders/slice";
import { showNotificationByPage } from "~/utils/local_notification";
import { settingsRefresh, settingsReload } from "./slice";

export const messages = {
  errorLoad: 'Loading setting failed',
};

function* settingsReloadSaga() {
  yield takeLatest(settingsReload.type, function* () {
    yield put(uiRefresh({ loading: true }));

    try {
      const response = yield call(settingsAPI.me);

      if (response && response.status === 200) {
        const { branch } = response.data;
        const settingsData = { ...branch.queue, partnerId: branch.partner_id }

        yield put(settingsRefresh({ data: settingsData }));
        yield setUser(settingsData);
      }
    } catch (error) {
      showNotificationByPage(RouteNames.KITCHEN_LINK, 'error', messages.errorLoad, error.message);
    }

    yield put(uiRefresh({ loading: false }));
  });
}

export default function* partnersListSaga() {
  yield all([fork(settingsReloadSaga)]);
}
