const API_PATH =
  process.env.REACT_APP_API_PATH || 'https://chc-ims.studioone.am/v1/queue';
const SOCKET_PATH =
  process.env.REACT_APP_SOCKET_PATH || 'wss://chc-ws.studioone.am';

export const API_URL = API_PATH;
export const SOCKET_URL = SOCKET_PATH;

export const siteConfig = {
	footerText : process.env.REACT_APP_VERSION,
};