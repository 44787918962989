import { notification } from 'antd';
import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { partnersAPI } from '~/helpers/api/partners';
import { listRefresh, listReload, uiRefresh } from './slice';

export const messages = {
  errorListLoad: 'Loading partner list failed',
};

function* listReloadSaga() {
  yield takeLatest(listReload.type, function* () {
    yield put(uiRefresh(true));

    try {
      const response = yield call(partnersAPI.partnersList);

      if (response && response.status === 200) {
        yield put(listRefresh(response.data));
      }
    } catch (error) {
      notification.error({
        message: messages.errorListLoad,
        description: error.message,
      });
    }

    yield put(uiRefresh(false));
  });
}

export default function* partnersListSaga() {
  yield all([fork(listReloadSaga)]);
}
