import { notification } from 'antd';
import { all, call, fork, put, takeEvery } from 'redux-saga/effects';
import { authApi } from '~/helpers/api/auth';
import { setToken, setUser } from "~/helpers/utility";
import { settingsRefresh } from "~/store/setting/slice";
import { reconnect } from '~/store/socket/slice';
import { login, loginError, loginSuccess, logout, setIsAuth, uiRefresh } from "./slice";

export function clearLocalStorage() {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
}

export function* loginRequest() {
  yield takeEvery(login.type, function* ({ payload }) {
    yield put(uiRefresh({ loading: true }));

    try {
      const response = yield call(authApi.apiLogin, payload.data);

      if (response && response.status === 200) {
        const { data } = response.data;

        yield put(loginSuccess(data));
        yield put(setIsAuth(true));

        yield setToken(data.token);
        yield setUser({ ...data.branch.queue, partnerId: data.branch.partner_id });

        yield put(reconnect({ authToken: data.token }));
      } else {
        yield put(loginError());
      }
    } catch (error) {
      notification.error({
        message: 'Login failed',
        description: error.message,
      });
      yield put(loginError());
    }

    yield put(uiRefresh({ loading: false }));
  });
}

export function* loginErrorSaga() {
  yield takeEvery(loginError.type, function* () {
    yield call(clearLocalStorage);
    yield put(setIsAuth(false));
    yield put(reconnect({ authToken: null }));
    yield put(uiRefresh({ loading: false }));
  });
}

export function* logOut() {
  yield takeEvery(logout.type, function* () {
    yield put(settingsRefresh({ data: null }));
  });
}

export default function* authSaga() {
  yield all([fork(loginRequest), fork(loginErrorSaga), fork(logOut)]);
}
