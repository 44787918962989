export enum ProductUIStatus {
  OVERDUE_AND_PREPARE = 'Processing',
  PREPARE = 'Prepare',
  READY = 'Ready to receive',
  OVERDUE = 'Overdue',
  ALL = 'All',
  NEW = 'New',
}

export enum ProductDataStatus {
  OVERDUE_AND_PREPARE = 'OverdueAndProcessing',
  PROCESSING = 'Processing',
  COMPLETED = 'Completed',
  OVERDUE = 'Overdue',
  ALL = 'All',
  NEW = 'New',
}
