import { all, call, fork, put } from "redux-saga/effects";
import { setSettingsUpdatedValue } from "~/store/setting/slice";
import { SETTINGS_UPDATE } from "~/store/setting/types";

function* settingsUpdateSaga(action) {
  switch (action.type) {
    case SETTINGS_UPDATE: {
      yield call(settingsUpdated);
      break;
    }
    default:
      break;
  }
}

function* settingsUpdated() {
  yield put(setSettingsUpdatedValue(true));
}

export default function* settingsSocketSaga(action) {
  yield all([fork(settingsUpdateSaga, action)]);
}
