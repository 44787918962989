import { apiRequest } from './index';
/* ------------------------------------------------------------------------------------------------
    Main
------------------------------------------------------------------------------------------------ */

// get list of partners
function partnersList(params = {}) {
  const req = {
    method: 'GET',
    url: '/partners',
    params,
  };

  return apiRequest(req);
}

export const partnersAPI = {
  partnersList,
};
